.modal {
  background-color: rgb(32, 32, 32, 0.8);
  height: 95vh;
}
.w3-modal {
  z-index: 3;
  display: none;
  padding-top: 10px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.header,
.footer-modal {
  background-color: #fafafa !important;
}
.footer-modal {
  margin-bottom: 30px;
}
.info-property-content {
  display: flex;
  column-gap: 30px;
}
.info-section {
  width: 50%;
}
.property-info-section {
  width: 60%;
}
.info-section p,
.info-section h2 {
  text-align: left;
}
.info-section h2 {
  font-size: 14px;
}
.image-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.download-icon {
  display: inline-block;
  text-align: center;
  padding: 8px 12px;
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.download-icon:hover {
  background-color: #45a049;
}

.w3-image {
  object-fit: cover !important;
  width: 100% !important;
  max-width: 100% !important;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .modal-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    left: 5%;
  }
  .info-property-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
