.property-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  background-color: #f9f9f9;
  font-family: 'Arial', sans-serif;
}

.property-details {
  flex: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.property-details h1 {
  font-size: 32px;
  color: #333;
}

.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.info-grid p {
  font-size: 16px;
  text-align: left;
  color: #444;
}

.property-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  float: left;
}
.property-details button {
  background-color: green;
  border: none;
  font-size: 14px;
  color: white;
  border-radius: 5px;
}
.return-button button {
  background-color: orangered;
  border: none;
  font-size: 14px;
  color: white;
  border-radius: 5px;
}
.property-details button:hover {
  cursor: pointer;
}
.return-button button:hover {
  cursor: pointer;
}
.property-image {
  width: 100px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.buyers-section {
  flex: 1;
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.buyers-section h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.buyers-table {
  width: 100%;
  border-collapse: collapse;
}

.buyers-table th,
.buyers-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.buyers-table th {
  background-color: #f0f0f0;
}

.buyers-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.buyers-table tr:hover {
  background-color: #f1f1f1;
}

.buyers-table td {
  font-size: 16px;
  color: #555;
}
@media (max-width: 768px) {
  .property-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 40px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
  }
}
