.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex-grow: 1;
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.content {
  margin-left: 250px; /* Same as sidebar width */
  margin-top: 60px; /* Height of the navbar */
  padding: 20px;
  width: calc(100% - 250px);
  overflow-y: auto;
}
