/* AddEditFormProperty.css */

.modal-content {
  background: rgba(253, 252, 252, 0.9);
  padding: 20px;
  border-radius: 10px;
  overflow: auto;
  max-height: 80vh;
}

.infor-category--container {
  border: 1px solid rgba(199, 199, 199, 0.5);
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.form-label {
  flex: 1;
  margin-right: 20px;
}

.w3-button {
  background-color: red;
  color: white;
  padding: 2px 10px;
  border-radius: 5px;
  margin: 30px 20px 0 0;
  display: block;
}

.w3-button:hover {
  background-color: rgb(253, 62, 62);
  cursor: pointer;
}
