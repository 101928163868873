/* Sidebar container */
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  color: #ffffff;
  border: 1px solid rgb(220, 220, 220, 0.9);
  padding: 20px;
  position: fixed;
  top: 90px;
  left: 0px;

  height: calc(100% - 60px);
  transition: left 0.3s ease;
  z-index: 0;
}

.sidebar.open {
  left: 0;
}

/* Sidebar header */
.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.welcome {
  font-size: 16px;
  font-weight: bold;
}

/* Sidebar list */
.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgb(220, 220, 220, 0.9);
  padding: 5px 10px;
  font-size: 15px;
}

.sidebar ul li a {
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar ul li .icon {
  margin-right: 10px;
  font-size: 25px;
  color: #cc9900;
}
.sidebar ul li .icon:hover {
  color: black !important;
  z-index: 2;
}
.sidebar ul li:hover {
  background-color: rgb(253, 211, 103, 0.5);
}
.request-count {
  background-color: red;
  color: white;
  border-radius: 12px;
  padding: 0 8px;
  margin-left: auto;
}

/* Log out button */
.logout {
  margin-top: auto;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  background-color: #ff7f50;
}

.logout-button {
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  background-color: #ff7f50;
  gap: 20px;
}

/* Media Queries for Small Screens */
@media (max-width: 768px) {
  .sidebar {
    background-color: #f8f9fa;
    color: #ffffff;
    padding: 20px;
    position: fixed;
    top: 100px;
    left: -285px;
    height: calc(100% - 30px);
    transition: left 0.3s ease;
    z-index: 2;
  }

  .sidebar.open {
    left: 0;
    width: 205px;
    top: 134px;
  }

  .sidebar-header {
    justify-self: left;
  }

  .avatar {
    margin-right: 0;
  }

  .welcome {
    text-align: center;
  }

  .sidebar ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .sidebar ul li {
    justify-content: flex-start;
    margin: 5px 0 !important;
  }

  .sidebar ul li a {
    justify-content: flex-start;
    font-size: 12px;
  }
  .sidebar ul li .icon {
    margin-right: 10px;
    font-size: 20px;
    color: #cc9900;
  }
  .request-count {
    margin-left: 10px;
  }
}
