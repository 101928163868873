.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 2px 20px;
  border-bottom: 1px solid #e0e0e0;
  position: fixed;
  width: 100%;
  z-index: 1; /* Lower z-index */
}
.logo-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 260px;
  margin-top: 10px;
}
.navbar-logo img {
  height: 80px;
}
.navbar-subcontainer {
  border: #f8f9fa 1px solid;

  display: flex;
}
.navbar-search input {
  padding: 5px 10px;
  font-size: 16px;
}

.navbar-icons {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 30px !important;
}

.navbar-icons i {
  cursor: pointer;
}
.icon-notifications,
.icon-messages,
.icon-menu {
  height: 20px;
  font-size: 30px !important;
  color: #cc9900;
}
.icon-menu {
  display: none;
}
.icon-profile {
  display: flex;
  align-items: center;
}
/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .logo-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-top: 0px;
  }
  .navbar-logo {
    margin-bottom: 10px;
  }

  .navbar-search input {
    width: 100%;
    margin-bottom: 0px;
  }

  .navbar-icons {
    width: 100%;
    justify-content: space-around;
  }

  .icon-menu {
    display: block;
  }
}
