.PropertyDetailsModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.PropertyDetailsModal-content {
  width: 70%;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.property-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.property-images img {
  max-width: 100px;
  margin: 5px;
  border-radius: 5px;
}

.property-details {
  margin-top: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  background-color: red;
  font-size: 10px;
  cursor: pointer;
  color: white;
}
