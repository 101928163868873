.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.form-label {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.form-label input,
.form-label textarea,
.form-label select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-label textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
}

.submit-button:hover {
  background-color: green;
}

/* Media Queries for smaller screens */
@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
  }

  .form-label {
    margin: 5px 0;
  }
}
