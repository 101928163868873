.requests-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.requests-container--filter {
  display: flex;
  gap: 40px;
}

.filters-container {
  display: flex;
  gap: 20px;
}

select,
input[type='number'] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  padding: 10px;
  background-color: #f4f4f4;
}

tbody td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
tbody tr:hover {
  background-color: rgb(5, 206, 156, 0.2);
  cursor: pointer;
}

.price {
  font-size: 18px;
  color: green;
  border-radius: 4px;
  padding: 1px 2px;
  font-weight: 900;
  margin-left: 5px;
}

.price-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.name h4 {
  color: gray;
  font-weight: 500;
}

.price-filter input {
  width: 48%;
}

.timestamp {
  width: 120px;
  text-align: center;
}

.time {
  font-size: 12px;
  color: #888;
}

.active {
  font-weight: bold;
}

.today {
  color: #28a745;
}

.yesterday {
  color: #dc3545;
}

.older {
  color: #6c757d;
}

.request-view--button {
  background-color: rgb(179, 255, 0);
  border: none;
  margin: 1px 2px;
  padding: 2px 10px;
  border-radius: 2px;
}
.request-delete--button {
  background-color: red;
  border: none;
  margin: 1px 2px;
  padding: 2px 5px;
  border-radius: 2px;
}
.request-view--button:hover {
  cursor: pointer;
}
.request-delete--button {
  cursor: pointer;
}
@media (max-width: 600px) {
  .requests-container--filter {
    display: flex;
    gap: 40px;
    flex-direction: column;
  }
  .price-filter {
    flex-direction: column;
  }

  .price-filter input {
    width: 100%;
    margin-bottom: 10px;
  }
  .requests-list {
    flex-direction: column;
  }
  .timestamp {
    width: 70px;
    text-align: center;
  }
  .notification .avatar {
    display: none;
  }
  .proprty_location {
    display: none;
  }
  .view-delete-container {
    flex-direction: column;
  }
}
